class ClickShow
{
    static getSelector()
    {
        return '[data-action="click-show"]';
    }

    constructor(element)
    {
        element.addEventListener('click', () => {
            element.remove();

            document.querySelectorAll(`[data-name=${element.dataset.related}]`).forEach(target => {
                target.classList.remove('is-hidden');
            });
        });
    }
}
export default ClickShow;
