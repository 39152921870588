class ShowScrollToTop
{
    constructor()
    {
        this.topButton = document.getElementById('goto-top');

        if (!this.topButton) {
            return;
        }

        this.scrollElement = 'scrollingElement' in document ? document.scrollingElement : document.documentElement;

        window.addEventListener('scroll', () => {
            const position = this.scrollElement.scrollTop;

            if (250 < position) {
                this.topButton.classList.add('is-active');
            } else {
                this.topButton.classList.remove('is-active');
            }
        }, false);
    }
}

export default ShowScrollToTop;
