/**
 * Polyfills for IE 11
 */

// element.closest()
import elementClosest from 'element-closest';
elementClosest(window);

// element.remove()
if (!Element.prototype.remove) {
    Element.prototype.remove = function remove() {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}

// scroll.({ behavior: 'smooth' })
import smooth from 'smoothscroll-polyfill';
smooth.polyfill();

// new Event(), new CustomEvent() => new window.CustomEvent()
import 'custom-event-polyfill';


// Solve fixed background scrolling bug
if(navigator.userAgent.match(/Trident\/7\./)) {
    document.body.addEventListener("mousewheel", event => {
        event.preventDefault();
        const wd = event.wheelDelta;
        const csp = window.pageYOffset;
        window.scrollTo(0, csp - wd);
    });
}