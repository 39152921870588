class GlobalNav
{
    constructor()
    {
        const navbar = document.querySelector('#global-navbar');

        window.addEventListener("scroll", () => {
            const offset = window.pageYOffset;

            if (50 < offset) {
                navbar.classList.add('scrolling');
            } else {
                navbar.classList.remove('scrolling');
            }
        });
    }
}
export default GlobalNav;
