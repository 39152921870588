// polyfills
import './polyfills';

// application
import SmoothLink from "./modules/smooth-link";
import ScrollFadein from "./modules/scroll-fadein";
import ClickShow from "./modules/click-show";
import ShowScrollToTop from "./modules/show-scroll-to-top";
import GlobalNav from "./modules/global-nav";
import MobileBurgerMenu from "./modules/mobile-burger-menu";
import LockSubmitButton from "./modules/lock-submit-button";
import Gallery from "./modules/gallery";


/**
 * Add modules after DOM loaded
 */
document.addEventListener('DOMContentLoaded', () => {
    [
        SmoothLink, ScrollFadein, ClickShow, ShowScrollToTop, GlobalNav, MobileBurgerMenu, LockSubmitButton, Gallery,

    ].forEach(module => {
        // Traverse DOM
        if (module.hasOwnProperty('getSelector')) {
            document.querySelectorAll(module.getSelector()).forEach((element) => {
                new module(element);
            });
        } else {
            new module();
        }
    });
});
