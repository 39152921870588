class ScrollFadein
{
    constructor()
    {
        this.elements = [];
        this.addClass();

        if (this.elements.length) {
            this.registerEvent();
            window.dispatchEvent(new window.CustomEvent('scroll'));
        }
    }

    addClass()
    {
        const selectors = '.section-about,.section-rinji-notices,.section-user-notices,.section-news,.section-map,section-sns,.section-contact';

        document.querySelectorAll(selectors).forEach(section => {
            section.querySelectorAll('h2,h3,li,dd,dt,p,table,select,input,textarea').forEach(element => {
                element.classList.add('fadein');
                this.elements.push(element);
            });
        });
    }

    registerEvent()
    {
        window.addEventListener("scroll", () => {
            const showTiming = window.innerHeight > 768 ? 50 : 25; // 要素が出てくるタイミング
            const scrollY = window.pageYOffset; // スクロール量
            const windowH = window.innerHeight; // ブラウザウィンドウのビューポート(viewport)の高さ

            for(let i = 0; i < this.elements.length; i++) {
                const elemClientRect = this.elements[i].getBoundingClientRect();
                const elemY = scrollY + elemClientRect.top;

                if(scrollY + windowH - showTiming > elemY) {
                    this.elements[i].classList.add('is-show');

                } else if(scrollY + windowH < elemY) {
                    // 上にスクロールして再度非表示にする場合
                    // this.elements[i].classList.remove('is-show');
                }
            }
        });
    }
}
export default ScrollFadein;
