class LockSubmitButton
{
    static getSelector()
    {
        return '[data-submit-lock]';
    }


    constructor(element)
    {
        if (element.tagName === 'A') {
            element.addEventListener('click', () => {
                this.lockButton(element);
            });
        } else {
            element.closest('form').addEventListener('submit', () => {
                this.lockButton(element);
            });
        }
    }

    lockButton(button)
    {
        button.style.width = `${button.offsetWidth}px`;

        if (button.dataset.submitLock) {
            button.innerHTML = button.dataset.submitLock;
        }

        document.querySelectorAll('[data-submit-lock]').forEach(element => {
            element.setAttribute('disabled', 'disabled');
        });
    }
}

export default LockSubmitButton;
