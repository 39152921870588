class SmoothLink
{
    constructor()
    {
        const hash = location.hash;

        if (hash) {
            window.scrollTo(0, 0);

            setTimeout(() => {
                window.scrollTo(0, 0);

                setTimeout(() => {
                    this.goto(hash.replace(/.*?#(.+)/g, '$1'));
                }, 100);
            }, 1);
        }

        document.querySelectorAll('a[href^="#"]').forEach(element => {
            element.addEventListener('click', event => {
                event.preventDefault();

                const id = element.getAttribute('href').replace(/.*?#(.+)/g, '$1');
                this.goto(id);
            });
        });
    }

    goto(targetId)
    {
        const target = document.getElementById(targetId);
        const top = target.offsetTop - this.getGlobalNavHeight();

        window.scroll({ top: top, left: 0, behavior: 'smooth' });
    }

    getGlobalNavHeight()
    {
        const globalNav = document.querySelector('#global-navbar');
        return !globalNav ? 0 : globalNav.clientHeight;
    }
}

export default SmoothLink;
