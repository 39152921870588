class MobileBurgerMenu
{
    static getSelector()
    {
        return '.navbar';
    }

    constructor(navbar)
    {
        const burger = navbar.querySelector('.navbar-burger');

        if (!burger) {
            return;
        }

        burger.addEventListener('click', () => {
            const target = burger.dataset.target;
            const $target = document.getElementById(target);

            navbar.classList.toggle('is-active');
            burger.classList.toggle('is-active');
            $target.classList.toggle('is-active');
        });
    }
}

export default MobileBurgerMenu;
